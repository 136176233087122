import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Container } from '../blocks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Container;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About`}</h1>
    <p>{`Hey, I'm Viraj Chavan, a Software Engineer graduated in 2018 from `}<a href="https://en.wikipedia.org/wiki/Walchand_College_of_Engineering,_Sangli" target="_blank">{`WCE`}</a>{`, Sangli, India.`}</p>
    <blockquote>
      <p parentName="blockquote">{`I love building and shipping things people will actually use and enjoy thinking about the user and their perspective as well as the technical solution.`}</p>
    </blockquote>
    <p>{`I have been writing code since 2016, but my professional experience started in January 2018.`}</p>
    <p>{`I like to travel, read books, ride a bicycle, build stuff, explore new things and more!`}<br />{`
I want to experience being a digital nomad for a while.`}</p>
    <p>{`You can email me on `}<a href="mailto:virajc@live.com">{`virajc@live.com`}</a>{`.`}<br />{`
Find me on instagram: `}<a href="http://instagram.com/virajvchavan" target="_blank">{`virajvchavan`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      